import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contacts"} />
		<Helmet>
			<title>
				The Gentlemen’s Den Barbershop
			</title>
			<meta name={"description"} content={"Де традиція зустрічається з сучасним стилем"} />
			<meta property={"og:title"} content={"The Gentlemen’s Den Barbershop"} />
			<meta property={"og:description"} content={"Де традиція зустрічається з сучасним стилем"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66b9c1e8e778710018d28737/images/1-2%20%281%29.jpg?v=2024-08-12T09:42:13.462Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66b9c1e8e778710018d28737/images/1-2%20%281%29.jpg?v=2024-08-12T09:42:13.462Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66b9c1e8e778710018d28737/images/1-2%20%281%29.jpg?v=2024-08-12T09:42:13.462Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66b9c1e8e778710018d28737/images/1-2%20%281%29.jpg?v=2024-08-12T09:42:13.462Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66b9c1e8e778710018d28737/images/1-2%20%281%29.jpg?v=2024-08-12T09:42:13.462Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66b9c1e8e778710018d28737/images/1-2%20%281%29.jpg?v=2024-08-12T09:42:13.462Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66b9c1e8e778710018d28737/images/1-2%20%281%29.jpg?v=2024-08-12T09:42:13.462Z"} />
			<meta name={"msapplication-TileColor"} content={"#232323"} />
		</Helmet>
		<Components.Header>
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="text" />
		</Components.Header>
		<Section background="--color-dark" padding="80px 0 0 0">
			<Box
				display="flex"
				align-items="center"
				flex-direction="column"
				justify-content="center"
				padding="0px 200px 0px 200px"
				lg-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 30px 0px"
					font="--headline3"
					color="--light"
					text-align="center"
					sm-font="normal 700 62px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Контактна інформація
				</Text>
				<Text margin="0px 0px 48px 0px" font="--lead" color="--light" text-align="center">
					Відчуйте різницю, яку роблять професійні доглядальники. Забронюйте зустріч сьогодні та станьте частиною The Gentleman's Day Community.
				</Text>
				<Text margin="0px 0px 48px 0px" font="--headline3" color="--light" text-align="center">
					Bohdana Khmel'nyts'koho St, 5, Ternopil, Ternopil Oblast, 46001
					<br />
					0675588798
					<br />
					info@oplacanetiss.com
				</Text>
			</Box>
		</Section>
		<Components.Footer>
			<Override slot="text2" />
			<Override slot="text3" />
			<Override slot="text1">
				Bohdana Khmel'nyts'koho St, 5, Ternopil, Ternopil Oblast, 46001
				<br />
				0675588798
				<br />
				info@oplacanetiss.com
			</Override>
			<Override slot="text" />
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6166a2b829a0a1001e6ca5fb"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});